<template>
    <div></div>
</template>
  
<script>
import {
    AirwallexPayment
} from "@/api/stripePay";
import { redirectToCheckout, loadAirwallex } from 'airwallex-payment-elements';
import { Toast } from "vant";
loadAirwallex({
    env: 'prod', // Can choose other production environments, 'staging | 'demo' | 'prod'
});

export default {
    data() {
        return {
            email:'',
        };
    },
    mounted: function () {

    },
    created() {
        let orderNum = this.$route.query.orderNum;
        this.email = this.$route.query.email;
        console.log(orderNum)
        this.payByAirwallex(orderNum)
    },
    methods: {
        //空中云汇支付
        payByAirwallex(orderNum) {
            Toast.loading({
                message: "发起支付中，请稍等!",
                forbidClick: true,
                duration: 0,
            });
            AirwallexPayment({
                order_num: orderNum,
                email: this.email,
            }).then((response) => {
                console.log(response)

                const intent_id = response.intent_id;
                const client_secret = response.client_secret;
                const currency = response.currency;
                redirectToCheckout({
                    env: 'prod',
                    mode: 'payment',
                    currency,
                    intent_id, // Required, must provide intent details
                    client_secret, // Required
                    // theme: {
                    //     // Must provide theme to display the checkout page properly
                    //     fonts: [
                    //         // Customizes the font for the payment elements
                    //         {
                    //             src: 'https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2',
                    //             family: 'AxLLCircular',
                    //             weight: 400,
                    //         },
                    //     ],
                    // },
                    successUrl: 'https://wx.playhy.com/#/payAppSuccess', // Must be HTTPS sites
                    failUrl: 'https://wx.playhy.com/#/payAppFail', // Must be HTTPS sites
                });
            }).catch(() => {
                Toast.clear(true)
                this.$toast.fail('发起支付失败')
            })
        },
    }
}
</script>
  